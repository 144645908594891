@import "libs/common/styles/colors";
@import "libs/common/styles/general";
@import "libs/common/styles/responsive";

.dashboardCard {
  position: relative;
  display: grid;
  align-items: center;
  align-content: center;
  height: 130px;
  box-shadow: 0 3px 10px $shadow-grey-2;
  border-radius: 10px;
  padding-left: 2.188rem;
  overflow: hidden;

  .value {
    @include Rubik;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.75rem;
    letter-spacing: 0;
    span {
      font-size: 1.25rem;
      font-weight: 400;
    }
  }

  .titleContainer, .content {
    @include Rubik;
    text-align: left;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1.188rem;
  }

  .titleContainer {
    font-weight: 700;
    text-transform: uppercase;
  }

  @include desktop {
    min-width: 365px;
  }

  .content {
    font-weight: 300;
  }

  svg {
    position: absolute;
    font-size: 160px;
    right: -40px;
  }

  &.default {
    background: $mid-grey;

    .value {
      color: $blue;
    }

    .titleContainer {
      color: $blue;
    }

    .content {
      color: $blue;
      max-width: 180px;
    }

    svg {
      color: $blue-6;
    }
  }

  &.warning {
    background: $pastel-pink;

    .value {
      color: $orange;
    }

    .titleContainer {
      color: $orange;
    }

    .content {
      color: $orange;
      max-width: 200px;
    }

    svg {
      color: $orange-3;
    }
  }

  &.danger {
    background: $pink;
    .value {
      color: $red;
    }

    .titleContainer {
      color: $red;
    }

    .content {
      color: $red;
      max-width: 180px;
    }

    svg {
      color: $red-3;
    }
  }

  &.success {
    background: $light-green;

    .value {
      color: $green;
    }

    .titleContainer {
      color: $green;
    }

    .content {
      color: $green;
      max-width: 180px;
    }

    svg {
      color: $green-3;
    }
  }

  &.info {
    background: $light-yellow;

    .value {
      color: $yellow;
    }

    .titleContainer {
      color: $yellow;
    }

    .content {
      color: $yellow;
      max-width: 180px;
    }

    svg {
      color: $yellow-3;
    }
  }

  &.faCheck {
    svg {
      font-size: 150px;
      right: -33px;
    }
  }

  &.faClock {
    svg {
      font-size: 160px;
      right: -40px;
    }
  }

  &.faCreditCard {
    svg {
      font-size: 110px;
      right: -7px;
    }
  }

  &.faMoneyBillWave {
    svg {
      font-size: 100px;
      right: -7px;
    }
  }

  &.faTimes {
    svg {
      font-size: 210px;
      right: -27px;
    }
  }

  &.faProjectDiagram {
    svg {
      font-size: 120px;
      right: -33px;
    }
  }

  &.faCalculator {
    svg {
      font-size: 180px;
      right: -40px;
    }
  }

  &.faMoneyCheckAlt {
    svg {
      font-size: 120px;
      right: -33px;
    }
  }

  &.faHourglassHalf {
    svg {
      font-size: 160px;
      right: -20px;
    }
  }
}
