@import "../responsive";

.container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.25rem;
  height: min-content;

  @include desktop {
    grid-template-columns: repeat(2, 1fr);
  }

  @include for-big-desktop {
    grid-template-columns: repeat(4, 1fr);
  }

  .leftContainer {
    display: grid;
    gap: 2.25rem;
    height: min-content;
    @include desktop {
      grid-template-columns: 1fr 1fr;
    }
  }

  .rightContainer {
    display: grid;
    gap: 2.25rem;
    height: min-content;
  }
}
