@import "libs/common/styles/colors";
@import "libs/common/styles/general";
@import "libs/common/styles/responsive";

.breadcrumbs {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.1875rem;
  letter-spacing: 0;
  color: $blue;
  display: flex;
  margin: 0;
  list-style: none;
  padding-left: 1rem;

  li {
    position: relative;
    cursor: default;

    &:not(:last-of-type) {
      margin-right: 45px;

      &:after {
        position: absolute;
        right: -25px;
        top: calc(50% - 2.5px);
        content: "";
        height: 5px;
        width: 5px;
        background: $blue;
        border-radius: 50%;
      }
    }
  }

  @include tablet {
    display: none;
  }
}

.bold {
  @include bold;
}
