@import "libs/common/styles/colors";
@import "libs/common/styles/general";
@import "libs/common/styles/responsive";

.header {
  display: grid;
  grid-template-columns: 285px auto max-content;
  align-items: center;
  height: 75px;
  padding-right: 2.25rem;
  background: $white;
  box-shadow: 0 3px 20px $shadow-grey;
  gap: 1rem;

  &.formHandler {
    grid-template-columns: 285px auto;
  }

  .headerNavs {
    display: flex;
    align-items: center;
    width: 100%;

    .restaurantSelector {
      display: grid;
      grid-template-columns: max-content auto;
      align-items: center;
      height: 75px;
      width: 100%;
      background: $blue;
      padding-left: 2rem;
      padding-right: 0;

      span {
        cursor: pointer;
      }

      .select {
        position: relative;
        z-index: 9999999999999999999;
      }
    }

    .menuToggle {
      color: $blue;
    }

  }

  @include tablet {
    grid-template-columns: 285px auto 0;
  }

  &.closed {
    @include tablet {
      grid-template-columns: 0 auto max-content;
      .headerNavs {
        width: 0;
        min-width: 0;
        min-height: 0;

        * {
          min-width: 0 !important;
          min-height: 0 !important;
          width: 0 !important;
          padding: 0 !important;
        }
      }
    }
  }

  &:not(.closed) {
    @include tablet {
      .userInfoContainer {
        .userInfo {
          display: none;
        }

        .locale {
          display: none;
        }
      }
    }
  }

  .breadcrumbs {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.1875rem;
    letter-spacing: 0;
    color: $blue;
    display: flex;

  }

  .userInfoContainer {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 3rem;
    align-items: center;

    .locales {
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(3, max-content);
    }

    .locale {
      text-transform: capitalize;
      font-weight: bold;
      color: $blue;
      font-size: 1rem;
      line-height: 1.1875rem;
      letter-spacing: 0;
    }

    .userInfo {
      display: grid;
      gap: 5px;
      align-items: center;

      .name {
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.1875rem;
        letter-spacing: 0;
        color: $blue;
      }

      .position {
        font-size: 0.8125rem;
        line-height: 0.9375rem;
        font-weight: 300;
        letter-spacing: 0;
        color: $blue;
      }
    }

    .logoutLink {
      color: $blue;
    }
  }
}
